import styled   from '@emotion/styled';
import { rgba } from 'polished';

export const Container = styled.header`
  position: relative;
  z-index: 100;
  width: 100%;
  border-bottom: ${props => props.theme.config.components.header.accentBorder ? `5px solid ${props.theme.brand_color}` : 'none'};
  background-color: ${(props) => props.theme.base_color};
  ${props => props.sticky && `
    position: fixed;
  `}
  ${props => props.collapsed && `
    background-color: ${rgba(props.theme.base_color, 0.95)};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);

    .subnav {
      background-color: ${rgba(props.theme.base_color, 0.95)};
    }
  `}
`;

export const PrimaryHeader = styled.div`
  transition: height 0.3s ease;
  height: ${props => props.theme.config.components.header.fullHeight};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  img {
    transition: height 0.2s ease;
    margin: 0;
  }

  ${props => props.collapsed && `
    padding-top: 0;
    height: ${props.theme.config.components.header.collapsedHeight};
  `}
`;

export const Logo = styled.div`
  max-width: ${props => props.maxWidth && props.maxWidth < 300 ? `${props.maxWidth}px` : '300px'};
  max-height: 120px;
  flex-shrink: 0;
  flex-grow: 1;

  img {
    transition: height 0.2s ease;
    margin: 0;
  }

  ${props => props.collapsed && `
    max-height: 50px;

    .gatsby-image-wrapper {
      height: 50px !important;
    }

    picture > img {
      height: 50px !important;
      object-fit: contain !important;
    }
  `}
`;

export const DesktopNavigation = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
`;

export const MobileNavigation = styled.div`
  position: absolute;
  z-index: 9999;
  //TODO do not hardcode these values
  top: ${props => props.visible ? props.theme.config.components.header.fullHeight : '-1000px'};
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  margin: auto;
  right: 0;
  left: 0;
  transition: top 0.3s, visibility 0.2s;

  nav {
    padding: 0 5%;
    background: ${(props) => props.theme.base_color};
    overflow: scroll;
    max-height: 60vh;
    border-bottom: 5px solid ${props => props.theme.brand_color};

    ul {
      li {
        margin: 10px 0;

        a {
          width: 100%;
          display: block;
          // TODO read color from config
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
  }
`;

export const Toggler = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: unset;
  font-size: 1.5rem;
`;

export const RolexWatch = styled.div`
  width: 150px;
  height: 70px;
  background-color: #e9e9e9;
  @media all and (max-width: 540px) {
    margin: auto;
  }
`;

export const CartSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    color: unset;
    display: block;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
`;