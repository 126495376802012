import React, { useContext } from 'react';
import { navigate }          from 'gatsby';

import { LanguageContext } from '../../../providers';

const buildPath = (path, lang) => {
  lang = lang === 'de' ? '' : lang;
  if (path.includes('/en/')) {
    return path.replace('/en/', `/${lang}/`)
  } else if (path.includes('/fr/')) {
    return path.replace('/fr/', `/${lang}/`)
  } else {
    return `/${lang}${path}`;
  }
};

const LanguageToggler = ({ location }) => {
  const { setCurrentLanguage, availableLanguages, language } = useContext(LanguageContext);

  function onChange(event) {
    event.preventDefault();
    const lang = event.target.value;
    setCurrentLanguage(lang);
    navigate(buildPath(location.pathname, lang));
  }

  if (availableLanguages.length > 1) {
    return (
      <select id='lang' onBlur={onChange} value={language}>
        {availableLanguages.map(language => (
          <option key={language} value={language}>{language}</option>
        ))}
      </select>
    )
  } else {
    return null;
  }
};

export default LanguageToggler;
