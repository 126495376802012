import styled            from '@emotion/styled';
import { readableColor } from 'polished';

export const FooterWrapper = styled.footer`
  //position: absolute;
  bottom: 0;
  width: 100%;
`;

export const Footer = styled.div`
  background-color: ${props => props.theme.config.components.footer.backgroundColor};
  color: ${props => props.theme.config.components.footer.fontColor !== '' ? props.theme.config.components.footer.fontColor : readableColor(props.theme.config.components.footer.backgroundColor)};
  padding: 0 0 30px 0;
  h4 {
    margin-top: 30px;
    font-weight: 500;
  }
  address {
    font-style: normal;
    margin-bottom: 0;
  }
  ul {
    margin: 0;
    li {
      font-weight: 300;
      margin: 0;
      list-style-type: none;
      a {
        color: ${props => props.theme.config.components.footer.fontColor !== '' ? props.theme.config.components.footer.fontColor : readableColor(props.theme.config.components.footer.backgroundColor)};
        text-decoration: underline;
      }
    }
  }
`;

export const Subfooter = styled.div`
  background-color: ${props => props.theme.config.components.footer.subFooterBackgroundColor};
  color: ${props => props.theme.config.components.footer.subFooterFontColor !== '' ? props.theme.config.components.footer.subFooterFontColor : readableColor(props.theme.config.components.footer.subFooterBackgroundColor)};
`;

export const Inner = styled.div`
  padding-bottom: 50px;
  margin: auto;
  width: 100%;
  max-width: ${props => props.theme.config.components.wrapper.width}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.9rem;
  > div {
    margin: 0 20px;
    max-width: 25%;
    .int-free-text {
      margin-top: 10px;
      text-align: justify;
    }
    @media (max-width: 1260px) {
      max-width: unset;
      width: 50%;
    }
    @media (max-width: 640px) {
      max-width: unset;
      width: 100%;
    }
  }
  h4 {
    margin-top: 30px;
  }
  address {
    font-style: normal;
    margin-bottom: 0;
  }
  a {
      color: unset;
    }
  }
  ul {
    margin: 0;
    li {
      font-weight: 300;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
    }
  }
`;