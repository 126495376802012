import styled from '@emotion/styled';

export const Bar = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-left: 0;
  height: 60px;
  margin-top: 20px;
  li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    &:hover {
      color: ${props => props.theme.brand_color}; 
      border-color: ${props => props.theme.brand_color}; 
    }
  }
`;