import React                     from 'react';
import PropTypes                 from 'prop-types';
import { graphql, StaticQuery }  from 'gatsby';
import { FaBars, FaWindowClose } from 'react-icons/fa';

import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import LanguageToggler   from '@interness/web-core/src/components/modules/LanguageToggler';
import { findT }         from '@interness/web-core/src/components/_helpers';
import RolexClock        from '@interness/web-core/src/components/modules/RolexClock/RolexClock';
import Logo              from '@interness/web-core/src/components/media/Logo/Logo';
import { GlobalContext } from '@interness/web-core/src/providers';

import Navigation from './Navigation';
import * as S     from './styles';

// TODO REFACTOR THIS MESS
class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      options: {
        sticky: this.props.sticky,
        collapses: this.props.collapses,
        changePos: this.props.changePos,
        fullHeight: this.props.config.fullHeight,
        collapsedHeight: this.props.config.collapsedHeight,
        smallScreenThreshold: this.props.config.smallScreenThreshold,
      },
      collapsed: false,
      smallScreen: false,
    }
  }

  rolexRetailer = false;

  componentDidMount() {
    this.setState({
      ...this.state,
      mounted: true
    })
    this.handleWindowResize();
    this.handleScroll();
    window.addEventListener('resize', this.handleWindowResize);
    if (this.state.options.sticky && !this.state.smallScreen) {
      if (this.state.options.collapses) {
        window.addEventListener('scroll', this.handleScroll);
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleMain = () => {
    const main = window.document.querySelector('main');
    if (this.state.options.sticky && window.innerWidth > this.state.options.smallScreenThreshold) {
      main.setAttribute('style', `padding-top: ${this.state.options.fullHeight}`);
    } else {
      main.setAttribute('style', `padding-top: 0;`);
    }
  };

  handleScroll = () => {
    if (this.state.options.sticky) {
      const currPos = window.pageYOffset;
      if (currPos >= this.state.options.changePos) {
        this.setState({
          collapsed: true,
        });
      } else {
        this.setState({
          collapsed: false,
        });
      }
    } else {
      this.setState({
        collapsed: false,
      });
    }
  };

  //ugh
  handleWindowResize = () => {
    if (window.innerWidth < this.state.options.smallScreenThreshold) {
      this.setState({
        smallScreen: true,
        options: {
          sticky: false,
          collapses: false,
          changePos: this.props.changePos,
          fullHeight: this.props.config.fullHeight,
          collapsedHeight: this.props.config.collapsedHeight,
          smallScreenThreshold: this.props.config.smallScreenThreshold,
        }
      });
      this.handleMain();
    } else {
      this.setState({
        smallScreen: false,
        collapsed: false,
        options: {
          sticky: this.props.sticky,
          collapses: this.props.collapses,
          changePos: this.props.changePos,
          fullHeight: this.props.config.fullHeight,
          collapsedHeight: this.props.config.collapsedHeight,
          smallScreenThreshold: this.props.config.smallScreenThreshold,
        }
      });
      this.handleMain();
    }
  };

  render() {
    return (
      <StaticQuery query={query} render={data => {
        const menu = data.directusMenus ? findT(data.directusMenus.translations, this.props.lang).config : null;
        const newMenu = data.directusMenusNew ? data.directusMenusNew.config : null;
        const rolexData = data.directusProject.config[0].brand_config[0];
        const project = data.directusProject;
        const translations = findT(project.translations, this.props.lang);

        return (
          <GlobalContext.Consumer>
            {context => {
              const { primaryMenuOpen, togglePrimaryMenu } = context;
              const toggleMenu = () => togglePrimaryMenu(!primaryMenuOpen);
              return (
                <React.Fragment>
                  <S.Container sticky={this.state.options.sticky} collapsed={this.state.collapsed}>
                    <Wrapper>
                      <S.PrimaryHeader collapsed={this.state.collapsed}>
                        <S.Logo collapsed={this.state.collapsed}
                                maxWidth={project.logo.localFile.childImageSharp.original.width}>
                          <Link to='/'>
                            <Logo logo={project.logo.localFile} title={translations.seo_title}/>
                          </Link>
                        </S.Logo>
                        {primaryMenuOpen
                          ? this.state.smallScreen &&
                          <S.Toggler onClick={toggleMenu}
                                     aria-label={'Menu'}><FaWindowClose/></S.Toggler>
                          : this.state.smallScreen &&
                          <S.Toggler onClick={toggleMenu} aria-label={'Menu'}><FaBars/></S.Toggler>
                        }
                        <S.DesktopNavigation visible={this.state.mounted ? !this.state.smallScreen : false}>
                          <Navigation menu={menu} newMenu={newMenu}/>
                        </S.DesktopNavigation>
                        <S.CartSection id='int-cart' />
                        <LanguageToggler location={this.props.location}/>
                        {rolexData && rolexData.rolex_clock && <S.RolexWatch>
                          <RolexClock/>
                        </S.RolexWatch>}
                      </S.PrimaryHeader>
                    </Wrapper>
                  </S.Container>
                  <S.MobileNavigation visible={this.state.smallScreen && primaryMenuOpen}>
                    <Navigation menu={menu} newMenu={newMenu} direction='column' expanded={true}/>
                  </S.MobileNavigation>
                </React.Fragment>
              )
            }}
          </GlobalContext.Consumer>
        )
      }}/>
    )
  }
}

const query = graphql`
  query {
    directusProject {
      ...ProjectLogoWithSvg
      config {
        brand_config {
          rolex_clock
          rolex_api_key
        }
      }
      translations {
        seo_title
        language
      }
    }
    directusMenus(position: {eq: "header"}) {
      position
      translations {
        language
        config {
          displayName
          path
          anchor
          sub {
            displayName
            path
            anchor
          }
        }
      }
    }
    directusMenusNew(position: {eq: "primary"}) {
      language
      position
      config {
        anchor
        display_name
        groups {
          display_name
          sub {
            anchor
            display_name
            path
          }
        }
        path
        external_path
        route
        sub {
          display_name
          path
          anchor
          path_prefix
          external_path
        }
      }
    }
  }
`;

Header.propTypes = {
  sticky: PropTypes.bool,
  collapses: PropTypes.bool,
  changePos: PropTypes.number,
  accentBorder: PropTypes.bool,
};

Header.defaultProps = {
  sticky: true,
  collapses: true,
  changePos: 100,
  accentBorder: false,
};

export default Header;
