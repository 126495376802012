import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql }      from 'gatsby';

import { Link }            from '@interness/web-core/src/components/elements';
import { LanguageContext } from '@interness/web-core/src/providers';
import useScript           from '@interness/web-core/src/hooks/useScript';

const RolexClock = () => {
  const { directusProject } = useStaticQuery(query);
  let rolexClock = false;
  if (directusProject.config[0].brand_config[0]) {
    rolexClock = directusProject.config[0].brand_config[0].rolex_clock;
  }
  const { language } = useContext(LanguageContext);
  const [loaded] = useScript('https://static.rolex.com/retailers/clock/retailercall.js');
  useEffect(() => {
    if (rolexClock) {
      if (loaded) {
        let rdp;
        if (typeof window !== 'undefined') {
          rdp = new window.RolexRetailerClock();
        }
        const rdpConfig = {
          dealerAPIKey: directusProject.config[0].brand_config[0].rolex_api_key,
          lang: language,
          colour: 'gold'
        };
        try {
          if (typeof window !== 'undefined') {
            rdp.getRetailerClock(rdpConfig);
          }
        } catch (err) {
          console.log('Mounting Rolex Clock failed', err);
        }
      }
    }
  }, [directusProject.config, language, loaded, rolexClock]);
  if (rolexClock) {
    return (
      <Link to={'/uhren/rolex'} title={'Rolex'}>
        <div className='rolex-retailer-clock'/>
      </Link>
    )
  } else {
    return null;
  }
};

const query = graphql`
  query {
    directusProject(id: {ne: "dummy"}) {
      config {
        brand_config {
          rolex_clock
          rolex_api_key
        }
      }
    }
  }
`;

export default RolexClock;
