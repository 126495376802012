import React            from 'react';
import PropTypes        from 'prop-types';
import styled           from '@emotion/styled';
import MenuStructure    from '@interness/web-core/src/components/structure/MenuStructure/MenuStructure';
import NewMenuStructure from '@interness/web-core/src/components/structure/MenuStructure/NewMenuStructure';


const Nav = styled.nav`
  ul {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${props => props.direction};
    list-style-type: none;
    margin: 0;
    li {
      position: relative;
      padding: 0 1rem;
      margin: 5px 0;
      a {
        color: unset;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-weight: bold;
        padding-bottom: 2px;
        white-space: nowrap;
        &:hover {
          border-bottom: 1px solid black;
        }
        &:visited {
          color: unset;
        }
      }
      &:hover {
        .subnav {
          display: flex;
        }
      }
    }
  }
  .current {
    border-bottom: 1px solid ${props => props.theme.brand_color};
    color: ${props => props.theme.brand_color} !important;
  }
  .subnav {
    background-color: ${(props) => props.theme.base_color};
    position: ${props => props.expanded && props.direction === 'column' ? 'relative' : 'fixed'};
    display: ${props => props.expanded ? 'flex' : 'none'};
    flex-direction: column;
    li {
      padding: 0 1rem;
      margin: 5px 0;
    }
  }
`;

function Navigation({ menu, newMenu, direction, expanded, id }) {
  return (
    <Nav direction={direction} expanded={expanded} id={id}>
      {menu ? <MenuStructure menu={menu} /> : <NewMenuStructure menu={newMenu}/>}
    </Nav>
  )
}

Navigation.propTypes = {
  menu: PropTypes.array,
  direction: PropTypes.oneOf(['column', 'row']).isRequired,
  expanded: PropTypes.bool.isRequired,
};

Navigation.defaultProps = {
  direction: 'row',
  expanded: false,
};

export default Navigation;